angular.module('app')
    .factory('landmarkToPlaceTransformer', [function () {

        function createAddressComponent(name, type) {
            return {
                short_name: name,
                long_name: name,
                types: [type]
            };
        }

        return {
            from: function (landmark) {
                var place;

                place = {
                    name: landmark.address.placeName,
                    formatted_address: landmark.address.placeName + ', ' + landmark.address.suburb,
                    address_components: [
                        createAddressComponent(landmark.address.addressLine1, 'route'),
                        createAddressComponent(landmark.address.suburb, 'locality')
                    ],
                    geometry: {
                        location: {
                            lat: function () {
                                return landmark.address.latitude;
                            },
                            lng: function () {
                                return landmark.address.longitude;
                            }
                        }
                    },
                    types: ['street_address'],
                    custom_prediction_label: '(Custom Ingogo Result)',
                    boundary: landmark.boundary
                };

                if (landmark.address.streetNumber) {
                    place.address_components.splice(0, 0, createAddressComponent(landmark.address.streetNumber, 'street_number'));
                }

                if (landmark.address.postcode) {
                    place.address_components.push(createAddressComponent(landmark.address.postcode, 'postal_code'));
                }

                if (landmark.address.state) {
                    place.address_components.push(createAddressComponent(landmark.address.state, 'administrative_area_level_1'));
                }

                place.place_id = landmark.placeId;
                
                return place;
            }
        };
    }]);
